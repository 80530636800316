<template>
  <div class="default-table-view scrollbar">
    <div class="operation-row"
         :class="{single}">
      <div class="special-opts">
        <el-tooltip content="下载模板"
                    placement="top">
          <el-button icon="el-icon-download"
                     circle
                     v-if="downloadable"
                     :loading="downloadDisabled"
                     type="primary"
                     @click="downloadTemplate"></el-button>
        </el-tooltip>
        <el-tooltip content="批量添加"
                    placement="top">
          <el-button icon="el-icon-upload2"
                     circle
                     v-if="uploadable"
                     type="primary"
                     @click="drawer = true"></el-button>
        </el-tooltip>
        <el-tooltip content="新增"
                    placement="top">
          <el-button icon="el-icon-plus"
                     v-if="addable"
                     circle
                     type="primary"
                     @click="edit(null)"></el-button>
        </el-tooltip>
        <el-tooltip content="批量导出"
                    placement="top">
          <el-button icon="el-icon-s-management"
                     circle
                     v-if="exportable"
                     :loading="exportDisabled"
                     type="success"
                     @click="exportFile"></el-button>
        </el-tooltip>
        <el-tooltip content="批量删除"
                    placement="top">
          <el-button icon="el-icon-delete"
                     circle
                     v-if="batchRemovable"
                     type="danger"
                     @click="remove(null)"
                     :disabled="!selection.length"></el-button>
        </el-tooltip>
        <el-tooltip content="批量确认"
                    placement="top">
          <el-button icon="el-icon-check"
                     circle
                     v-if="confirmed"
                     type="primary"
                     @click="confirm(null)"
                     :disabled="!selection.length"></el-button>
        </el-tooltip>
        <el-tooltip content="批量启用"
                    placement="top">
          <el-button icon="el-icon-check"
                     circle
                     v-if="recovered"
                     type="primary"
                     @click="recover(null)"
                     :disabled="!selection.length"></el-button>
        </el-tooltip>
        <el-tooltip content="批量停用"
                    placement="top">
          <el-button icon="el-icon-video-pause"
                     circle
                     v-if="paused"
                     type="warning"
                     @click="pause(null)"
                     :disabled="!selection.length"></el-button>
        </el-tooltip>
        <slot name="appendButtons"></slot>
      </div>
      <slot name="search"></slot>
      <el-input v-if="filterable"
                class="keyword-input"
                clearable
                v-model="keyword"
                @keydown.native.enter="queryData"
                :placeholder="queryPlaceholder">
        <el-button slot="append"
                   @click="queryData"
                   type="primary"
                   size="mini">查询</el-button>
      </el-input>
    </div>
    <el-table ref="table"
              stripe
              :data="tableData"
              size="mini"
              border=""
              v-loading="loading"
              @sort-change="sortChange"
              @selection-change="handleSelectionChange"
              v-bind="tableProps">
      <el-table-column v-if="selectable"
                       type="selection"
                       header-align="center"
                       align="center"
                       width="50"></el-table-column>
      <el-table-column v-if="showIndex"
                       type="index"
                       header-align="center"
                       align="center"
                       label="序号"
                       width="50"></el-table-column>
      <slot name="prepend"></slot>
      <tabel-column-template v-for="h in firstPart"
                             :key="h.id"
                             :header="h"></tabel-column-template>
      <slot name="insertion"></slot>
      <tabel-column-template v-for="h in secondPart"
                             :key="h.id"
                             :header="h"></tabel-column-template>
      <slot name="append"></slot>
      <table-action-column v-if="showOptsCell"
                           :width="optCellWidth">
        <template slot-scope="scope">
          <el-tooltip content="查看详情"
                      placement="left">
            <el-button v-if="viewable"
                       circle
                       size="mini"
                       type="infor"
                       icon="el-icon-view"
                       @click="view(scope.row)"></el-button>
          </el-tooltip>
          <el-tooltip content="编辑"
                      placement="left">
            <el-button v-if="isEditButtonVisible(scope.row)"
                       circle
                       size="mini"
                       type="warning"
                       icon="el-icon-edit"
                       @click="edit(scope.row)"></el-button>
          </el-tooltip>
          <el-tooltip content="指派二级调度"
                      placement="left">
            <el-button v-if="isAssignButtonVisible(scope.row)"
                       circle
                       size="mini"
                       type="primary"
                       icon="el-icon-s-custom"
                       @click="jumpToAssignPage(scope.row)"></el-button>
          </el-tooltip>
          <el-tooltip content="审核"
                      placement="left">
            <el-button v-if="isAuditButtonVisible(scope.row)"
                       circle
                       size="mini"
                       type="success"
                       icon="el-icon-s-check"
                       @click="jumpToAuditPage(scope.row)"></el-button>
          </el-tooltip>
          <el-button v-if="removable"
                     type="warning"
                     size="mini"
                     @click="remove(scope.row)">删除</el-button>
        </template>
      </table-action-column>
    </el-table>
    <div class="table-pagination-wrapper"
         v-if="paging">
      <el-pagination @size-change="handleSizeChange"
                     @current-change="handleCurrentChange"
                     :current-page="currentPage"
                     :page-sizes="[10, 20, 50, 100]"
                     :page-size="pageSize"
                     :total="total"
                     layout="total, sizes, prev, pager, next, jumper"></el-pagination>
    </div>
    <el-drawer :title="uploadTitle"
               :visible.sync="drawer"
               direction="ttb"
               size="90%"
               @opened="handleOpened"
               :before-close="() => drawer = false">
      <div class="upload-area-wrapper"
           ref="upload">
        <el-upload drag
                   ref="elUpload"
                   :action="'/bengche' + uploadUrl + '/list'"
                   :headers="header"
                   :on-success="handleUploaded"
                   :on-error="handleUploadError"
                   :before-upload="beforeUpload">
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或
            <em>点击上传</em>
          </div>
        </el-upload>
      </div>
      <el-table v-if="uploadTableData"
                stripe
                :data="uploadTableData"
                size="mini"
                border=""
                :max-height="maxHeight">
        <el-table-column type="index"
                         header-align="center"
                         align="center"
                         label="序号"
                         width="50"></el-table-column>
        <tabel-column-template v-for="h in uploadColumns"
                               :key="h.id"
                               :header="h"></tabel-column-template>
      </el-table>
      <div class="upload-buttons"
           v-if="uploadTableData && uploadTableData.length">
        <el-button type="infor"
                   @click="drawer = false">取消</el-button>
        <el-button :loading="uploading"
                   type="primary"
                   @click="confirmUpload">确认</el-button>
      </div>
    </el-drawer>
    <slot></slot>
  </div>
</template>

<script>
import { download } from '../../utils'
import TabelColumnTemplate from './table-column-template'
import TableActionColumn from './table-action-column'

export default {
  name: 'table-view',

  components: { TabelColumnTemplate, TableActionColumn },

  data() {
    return {
      orderBy: '',
      keyword: '',
      tableData: [],
      currentPage: 1,
      total: 0,
      loading: false,
      selection: [],
      exportDisabled: false,
      downloadDisabled: false,
      drawer: false,
      header: {},
      uploadTableData: null,
      maxHeight: null,
      uploading: false,
    }
  },

  props: {
    initQuery: {
      type: Boolean,
      default: true,
    },
    url: String,
    deleteUrl: String,
    exportUrl: String,
    uploadTitle: String,
    uploadUrl: String,
    uploadColumns: Array,
    exportFileName: String,
    exportErrorMsg: String,
    downloadUrl: String,
    downloadFileName: String,
    confirmUrl: String,
    recoveredUrl: String,
    pausedUrl: String,
    idProp: {
      type: String,
      default: 'id',
    },
    keywordProp: {
      type: String,
      default: 'key',
    },
    filterable: {
      type: Boolean,
      default: true,
    },
    filterForm: Object,
    addable: {
      type: Boolean,
      default: false,
    },
    viewable: {
      type: Boolean,
      default: false,
    },
    editable: {
      type: [Boolean, Function],
      default: false,
    },
    removable: {
      type: Boolean,
      default: false,
    },
    batchRemovable: {
      type: Boolean,
      default: false,
    },
    exportable: {
      type: Boolean,
      default: false,
    },
    downloadable: {
      type: Boolean,
      default: false,
    },
    uploadable: {
      type: Boolean,
      default: false,
    },
    auditable: {
      type: [Boolean, Function],
      default: false,
    },
    assignable: {
      type: [Boolean, Function],
      default: false,
    },
    confirmed: {
      type: Boolean,
      default: false,
    },
    recovered: {
      type: Boolean,
      default: false,
    },
    paused: {
      type: Boolean,
      default: false,
    },
    paging: {
      type: Boolean,
      default: true,
    },
    queryPlaceholder: String,
    headers: Array,
    splitIndex: Number,
    pageSize: {
      type: Number,
      default: 20,
    },
    dataHandler: Function,
    selectable: Boolean,
    showIndex: Boolean,
    tableProps: Object,
    beforeExport: Function,
    customRemove: Function,
    single: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    optCellWidth() {
      let buttonCount = 0
      this.editable && buttonCount++
      this.removable && buttonCount++
      this.viewable && buttonCount++
      this.auditable && buttonCount++
      this.assignable && buttonCount++
      return buttonCount * 30 + 30
    },
    showOptsCell() {
      return this.editable || this.removable || this.viewable
    },
    firstPart() {
      if (this.splitIndex) {
        return this.headers.slice(0, this.splitIndex)
      }
      return this.headers
    },
    secondPart() {
      if (this.splitIndex) {
        return this.headers.slice(this.splitIndex)
      }
      return []
    },
  },

  watch: {
    drawer(v) {
      if (!v) {
        this.handleUploadError()
      }
    },
  },

  methods: {
    isEditButtonVisible(row) {
      if (this.editable) {
        if (typeof this.editable === 'function') {
          return this.editable(row)
        } else {
          return this.editable
        }
      } else {
        return false
      }
    },

    isAuditButtonVisible(row) {
      if (this.auditable) {
        if (typeof this.auditable === 'function') {
          return this.auditable(row)
        } else {
          return this.auditable
        }
      } else {
        return false
      }
    },

    isAssignButtonVisible(row) {
      if (this.assignable) {
        if (typeof this.assignable === 'function') {
          return this.assignable(row)
        } else {
          return this.assignable
        }
      } else {
        return false
      }
    },

    // 重置分页并获取数据
    queryData() {
      this.currentPage = 1
      this.fetch()
    },

    // 获取数据列表
    fetch() {
      this.loading = true
      let params = {}
      if (this.$slots.search) {
        params = { ...this.filterForm }
      } else if (this.filterable) {
        params[this.keywordProp] = this.keyword
      }
      if (this.paging) {
        params.pageNum = this.currentPage
        params.pageSize = this.pageSize
      }
      if (this.orderBy) {
        params.orderBy = this.orderBy
      }
      // 强制转换
      for (const p in params) {
        if (params.hasOwnProperty(p)) {
          const v = params[p]
          if (!v) {
            params[p] = null
          }
        }
      }
      this.$http({
        url: this.$http.adornUrl(this.url),
        method: 'post',
        data: this.$http.adornData(params),
      })
        .then((data) => {
          if (this.paging) {
            if (data && data.code === 200) {
              this.tableData = this.dataHandler
                ? this.dataHandler(data.datas.list)
                : data.datas.list
              this.total = data.datas.total
            } else {
              this.tableData = []
              this.total = 0
            }
          } else {
            this.tableData = this.dataHandler
              ? this.dataHandler(data.datas)
              : data.datas
          }
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },

    // 每页数
    handleSizeChange(val) {
      this.pageSize = val
      this.queryData()
    },

    // 当前页
    handleCurrentChange(val) {
      this.currentPage = val
      this.fetch()
    },

    // 多选
    handleSelectionChange(val) {
      this.selection = val
    },

    // 是否需要回退到上一页
    shouldRollback(removedCount) {
      if (!this.paging) return false
      return (this.total % this.pageSize) - removedCount === 0
    },

    // 删除
    remove(row) {
      if (this.customRemove) {
        return this.customRemove(row)
      }
      let params, url
      let rollback = false
      if (this.batchRemovable) {
        // 批量删除
        url = this.deleteUrl
        if (row || this.selection.length === 1) {
          const id = row ? row[this.idProp] : this.selection[0][this.idProp]
          // text = `确定对[id=${id}]的记录进行[删除]操作?`
          params = this.$http.adornData([id], false)
          rollback = this.shouldRollback(1)
        } else {
          const ids = this.selection.map((item) => item[this.idProp])
          // text = `确定对[id=${ids.join(',')}]的记录进行[批量删除]操作?`
          params = this.$http.adornData(ids, false)
          rollback = this.shouldRollback(ids.length)
        }
      } else {
        // 普通删除
        const id = row[this.idProp]
        // text = `确定对[id=${id}]的记录进行[删除]操作?`
        url = `${this.deleteUrl}/${id}`
        params = this.$http.adornData()
        rollback = this.shouldRollback(1)
      }
      if (this.paging && rollback) {
        this.currentPage--
      }

      this.$confirm('确定删除记录吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.$http({
            url: this.$http.adornUrl(url),
            method: 'post',
            data: params,
          }).then((data) => {
            if (data && data.code === 200) {
              this.fetch()
              this.$opts.success()
            }
          })
        })
        .catch(() => {})
    },

    // 删除
    confirm(row) {
      let params, url
      url = this.confirmUrl
      const ids = this.selection.map((item) => item[this.idProp])
      // text = `确定对[id=${ids.join(',')}]的记录进行[批量删除]操作?`
      params = this.$http.adornData(ids, false)

      this.$confirm('选择的记录已经确认好方量了吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.$http({
            url: this.$http.adornUrl(url),
            method: 'post',
            data: params,
          }).then((data) => {
            if (data && data.code === 200) {
              this.fetch()
              this.$opts.success()
            }
          })
        })
        .catch(() => {})
    },
    // 启用
    recover(row) {
      let params, url
      url = this.recoveredUrl
      const ids = this.selection.map((item) => item[this.idProp])
      // text = `确定对[id=${ids.join(',')}]的记录进行[批量删除]操作?`
      params = this.$http.adornData(ids, false)

      this.$confirm('请确认启用选择的记录吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.$http({
            url: this.$http.adornUrl(url),
            method: 'post',
            data: params,
          }).then((data) => {
            if (data && data.code === 200) {
              this.fetch()
              this.$opts.success()
            }
          })
        })
        .catch(() => {})
    },
    // 停用
    pause(row) {
      let params, url
      url = this.pausedUrl
      const ids = this.selection.map((item) => item[this.idProp])
      // text = `确定对[id=${ids.join(',')}]的记录进行[批量删除]操作?`
      params = this.$http.adornData(ids, false)

      this.$confirm('请确认停用选择的记录吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.$http({
            url: this.$http.adornUrl(url),
            method: 'post',
            data: params,
          }).then((data) => {
            if (data && data.code === 200) {
              this.fetch()
              this.$opts.success()
            }
          })
        })
        .catch(() => {})
    },
    // 跳转到详情界面
    view(row) {
      const query = row ? { id: row[this.idProp] } : {}
      localStorage.setItem(this.$route.name + '-info-row', JSON.stringify(row))
      this.$router.push({ name: this.$route.name + '-info', query })
    },

    // 跳转到编辑界面
    edit(row) {
      const query = row ? { id: row[this.idProp] } : {}
      localStorage.setItem(
        this.$route.name + '-update-row',
        JSON.stringify(row)
      )
      localStorage.setItem(this.$route.name + '-update-state', !!row)
      this.$router.push({ name: this.$route.name + '-update', query })
    },

    jumpToAuditPage(row) {
      this.$router.push({
        name: this.$route.name + '-verify',
        query: { id: row[this.idProp] },
      })
    },

    jumpToAssignPage(row) {
      this.$router.push({
        name: this.$route.name + '-assign',
        query: { id: row[this.idProp] },
      })
    },

    // 导出数据
    exportFile() {
      if (this.beforeExport && !this.beforeExport()) return
      this.exportDisabled = true
      // 强制转换
      const params = this.filterForm
      for (const p in params) {
        if (params.hasOwnProperty(p)) {
          const v = params[p]
          if (!v) {
            params[p] = null
          }
        }
      }
      download(this.exportUrl, params, this.exportFileName, this.exportErrorMsg)
        .then(() => {
          this.exportDisabled = false
        })
        .catch(() => {})
    },

    // 下载模板
    downloadTemplate() {
      this.downloadDisabled = true
      download(this.downloadUrl, {}, this.downloadFileName)
        .then(() => {
          this.downloadDisabled = false
        })
        .catch(() => {})
    },

    handleUploaded(data) {
      this.uploadTableData = (data.datas || []).map((r, i) => ({
        ...r,
        index: i + 1,
      }))
    },

    handleUploadError() {
      this.$refs.elUpload.clearFiles()
      this.uploadTableData = null
    },

    handleOpened() {
      const uploadEl = this.$refs.upload
      this.maxHeight =
        uploadEl.parentNode.parentNode.clientHeight -
        uploadEl.clientHeight -
        156
    },

    beforeUpload(file) {
      this.file = file
      if (!this.uploadColumns) {
        this.confirmUpload()
        return false
      }
    },
    sortChange(e) {
      let { order, prop } = e
      this.orderBy = { order, prop }
      this.queryData()
    },
    confirmUpload() {
      this.uploading = true
      let fd = new FormData()
      fd.append('file', this.file)
      this.$http({
        url: this.$http.adornUrl(this.uploadUrl + '/save'),
        method: 'post',
        data: fd,
      })
        .then((data) => {
          if (data.code === 200) {
            this.drawer = false
            this.queryData()
            this.$emit('showResult', data.datas)
          } else {
            this.handleUploadError()
          }
          this.uploading = false
        })
        .catch((error) => {
          console.log(error)
          this.uploading = false
        })
    },
  },

  created() {
    this.header.token = this.$cookie.get('token')
    if (this.initQuery) {
      this.queryData()
    }
  },
}
</script>

<style lang="scss">
.default-table-view {
  height: 100%;
  padding: 20px;
  .operation-row {
    margin-bottom: 15px;
    &.single {
      display: flex;
      flex-direction: row-reverse;
      justify-content: flex-end;
      .el-input-group {
        margin-right: 10px;
      }
      .el-checkbox {
        line-height: 36px;
        margin-right: 20px;
      }
    }
    .el-form {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      & > .el-input,
      & > .el-select {
        width: 150px;
        margin-right: 10px;
        margin-bottom: 15px;
      }
      & > .el-button {
        margin-right: 10px;
        margin-bottom: 15px;
      }
    }
    .keyword-input {
      width: 300px;
    }
    .special-opts {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      margin-bottom: 20px;
    }
  }
  .el-table {
    tr:not(:hover):not(.hover-row),
    .el-table__row--striped:not(:hover):not(.hover-row) {
      .primary-cell:not(th) {
        background: #409eff;
      }
      .success-cell:not(th) {
        background: #67c23a;
      }
      .warning-cell:not(th) {
        background: #e6a23c;
      }
      .danger-cell:not(th) {
        background: #f56c6c;
      }
      .summary-row {
        background: #eee;
      }
      .total-row {
        font-size: bold;
        background: #ccc;
      }
    }
    tr:hover,
    .el-table__row--striped:hover {
      .primary-cell:not(th) {
        background: #5cacfc;
      }
      .success-cell:not(th) {
        background: #7bc555;
      }
      .warning-cell:not(th) {
        background: #e2a954;
      }
      .danger-cell:not(th) {
        background: #f38888;
      }
    }
    .el-tag {
      margin-right: 10px;
      &.selectable {
        cursor: pointer;
      }
    }
  }
  .table-pagination-wrapper {
    margin-top: 15px;
    text-align: right;
  }
}
</style>
