<template>
  <el-table-column
    :prop="header.prop"
    :label="header.label"
    :width="header.width"
    :min-width="header.minWidth"
    header-align="center"
    align="center"
    :sortable="header.sortable"
    show-overflow-tooltip
    :formatter="header.formatter"
    v-bind="header.others"
  ></el-table-column>
</template>

<script>
export default {
  name: 'table-column-template',

  props: {
    header: Object
  }
}
</script>
