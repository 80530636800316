<template>
  <el-table-column
    fixed="right"
    header-align="center"
    align="center"
    :width="columnWidth"
    label="操作"
  >
    <template slot-scope="scope">
      <!-- <div :class="{expanded}" class="expand-action-icon" @click="toggleExpandState">
        <i :class="[expanded ? 'el-icon-d-arrow-right' : 'el-icon-d-arrow-left']"></i>
      </div> -->
      <template v-if="expanded">
        <slot :row="scope.row"></slot>
      </template>
    </template>
  </el-table-column>
</template>

<script>
export default {
  name: 'table-action-column',

  props: {
    width: Number
  },

  data () {
    return {
      expanded: true
    }
  },

  computed: {
    columnWidth () {
      return this.expanded ? this.width + 20 : 70
    }
  },

  methods: {
    toggleExpandState () {
      this.expanded = !this.expanded
    }
  }
}
</script>

<style lang="scss">
.expand-action-icon {
  cursor: pointer;
  display: inline-block;
  padding: 1px 15px 0;
  &.expanded {
    padding-left: 0;
  }
}
</style>
